import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AnonGuard } from './guards/anon.guard';
import { MainGuard } from './guards/main.guard';
import { ApiHttpClient } from './services/api-auth-client';
import { AuthService } from './services/auth.service';
import { AuthInterceptor } from "./services/AuthInterceptor";

@NgModule({
  providers: [
    ApiHttpClient,
    AuthService,

    AuthInterceptor,

    MainGuard,
    AnonGuard,

    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    }
  ]
})
export class CoreModule { }
