import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgPipesModule } from 'ngx-pipes';
import { DatatableComponent } from './components/datatable/datatable.component';
import { EmptyComponent } from './components/empty/empty.component';
import { FooterComponent } from './components/footer/footer.component';
import { LogsComponent } from './components/logs/logs.component';
import { PageComponent } from './components/page/page.component';
import { ServerinfoComponent } from './components/serverinfo/serverinfo.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { TableSearchComponent } from './components/table-search/table-search.component';
import { TextCopyDirective } from './directives/text-copy.directive';
import { DateTimeFormatPipe } from './pipes/datetime-format.pipe';
import { KeysPipe } from './pipes/keys.pipe';
import { SafePipe } from './pipes/safe.pipe';
import { PreserveNewlinesPipe } from './pipes/preserve-newlines.pipe';
import { NgxChartsModule } from '@swimlane/ngx-charts';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgPipesModule,
    NgxDatatableModule,
    NgxChartsModule,
  ],
  declarations: [
    KeysPipe,
    SafePipe,
    DateTimeFormatPipe,
    PreserveNewlinesPipe,

    TextCopyDirective,

    FooterComponent,
    SpinnerComponent,
    EmptyComponent,
    PageComponent,
    TableSearchComponent,
    LogsComponent,
    ServerinfoComponent,

    DatatableComponent
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgPipesModule,
    NgxDatatableModule,

    SpinnerComponent,
    EmptyComponent,
    FooterComponent,
    PageComponent,
    TableSearchComponent,
    LogsComponent,
    ServerinfoComponent,
    DatatableComponent,

    TextCopyDirective,

    KeysPipe,
    SafePipe,
    DateTimeFormatPipe,
    PreserveNewlinesPipe,
  ]
})
export class SharedModule { }
