import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ApiHttpClient } from '@core/services/api-auth-client';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-serverinfo',
  templateUrl: './serverinfo.component.html',
})
export class ServerinfoComponent implements OnInit {

  health$: Observable<any>;
  build$: Observable<any>;

  constructor(
    private http: ApiHttpClient,
    private anonhttp: HttpClient,
  ) { }

  ngOnInit(): void {
    this.health$ = this.http.get<any>("/health")
    this.build$ = this.anonhttp.get<any>(`${environment.filesUrl}/build.json`)
  }

}
