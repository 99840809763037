import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainGuard } from '@core/guards/main.guard';
import { MainLayoutComponent as DefaultLayoutComponent } from '@layouts/main/main-layout.component';

export const routes: Routes = [
  { path: 'auth', loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule) },
  {
    path: '',
    canActivate: [MainGuard],
    component: DefaultLayoutComponent,
    data: { title: 'Home' },
    children: [
      // { path: 'dashboard', data: { title: 'Dashboard' }, loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule) },
      { path: 'users', loadChildren: () => import('./users/users.module').then(m => m.UsersModule) },
      { path: 'stats', loadChildren: () => import('./stats/stats.module').then(m => m.StatsModule) },
      { path: 'subscriptions', loadChildren: () => import('./subscriptions/subscriptions.module').then(m => m.SubscriptionsModule) },
      { path: 'indicators', loadChildren: () => import('./indicators/indicators.module').then(m => m.IndicatorsModule) },
      { path: 'releases', loadChildren: () => import('./releases/releases.module').then(m => m.ReleasesModule) },
      { path: 'sockets', loadChildren: () => import('./sockets/sockets.module').then(m => m.SocketsModule) },
      { path: 'eventviewer', loadChildren: () => import('./eventviewer/eventviewer.module').then(m => m.EventViewerModule) },
      { path: 'debug', loadChildren: () => import('./debug/debug.module').then(m => m.DebugModule) },
      { path: 'tools', loadChildren: () => import('./tools/tools.module').then(m => m.ToolsModule) },
      { path: 'headlines', loadChildren: () => import('./headlines/headlines.module').then(m => m.HeadlinesModule) },
      { path: 'calx', loadChildren: () => import('./calx/calx.module').then(m => m.CalxModule) },
      { path: '', redirectTo: 'users', pathMatch: 'full', },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
