<div class="sidebar-card mt-3 pt-2 px-2 pb-0 align-items-start">
  <dl class="mb-0">
    <dt>Server started:</dt>
    <dd>{{(health$|async)?.started|date:"yyyy-MM-dd HH:mm:ss"}}</dd>
    <dt>Build at:</dt>
    <dd>{{(build$|async)?.date|date:"yyyy-MM-dd HH:mm:ss"}}</dd>
    <dt>Build hash:</dt>
    <dd>{{(build$|async)?.hash|slice:0:16}}</dd>
  </dl>
</div>
