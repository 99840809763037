import { Component, OnInit } from '@angular/core';
import { ApiHttpClient } from '@core/services/api-auth-client';
import { AuthService, User } from '@core/services/auth.service';
import { LocalStorage } from '@efaps/ngx-store';
import { map, mergeMap, Observable, Subscriber } from 'rxjs';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-dashboard',
  templateUrl: './main-layout.component.html'
})
export class MainLayoutComponent implements OnInit {

  menu = [
    { type: "item", label: 'Users', route: '/users', icon: 'fad fa-users fa-fw me-2' },
    { type: "item", label: 'Stats', route: '/stats', icon: 'fad fa-sack-dollar fa-fw me-2' },
    { type: "item", label: 'Subscriptions', route: '/subscriptions', icon: 'fad fa-coins fa-fw me-2' },
    { type: "item", label: 'Indicators', route: '/indicators', icon: 'fad fa-wave-square fa-fw me-2' },
    { type: "item", label: 'Releases', route: '/releases', icon: 'fad fa-calendar-alt fa-fw me-2' },
    { type: "item", label: 'Headlines', route: '/headlines', icon: 'fad fa-newspaper fa-fw me-2' },
    { type: "item", label: 'Sockets', route: '/sockets', icon: 'fad fa-network-wired fa-fw me-2' },
    { type: "title", label: 'CalX' },
    { type: "item", label: 'FXS', route: '/calx/fxs', icon: 'fad fa-calendar fa-fw me-2' },
    { type: "item", label: 'DFX', route: '/calx/dfx', icon: 'fad fa-calendar fa-fw me-2' },
    { type: "title", label: 'Events' },
    { type: "item", label: 'Eikon', route: '/eventviewer/eikon', icon: 'fad fa-exchange-alt fa-fw me-2' },
    // { type: "item", label: 'Forecast', route: '/eventviewer/forecast', icon: 'fad fa-exchange-alt fa-fw me-2' },
    { type: "item", label: 'Stripe', route: '/eventviewer/stripe', icon: 'fab fa-stripe-s fa-fw me-2' },
    { type: "item", label: 'Paypal', route: '/eventviewer/paypal', icon: 'fab fa-paypal fa-fw me-2' },
    { type: "item", label: 'FXS', route: '/eventviewer/fxs', icon: 'fad fa-calendar fa-fw me-2' },
    { type: "title", label: 'Tools' },
    { type: "item", label: 'Text-to-Speech', route: '/tools/tts', icon: 'fad fa-assistive-listening-systems fa-fw me-2' },
    { type: "item", label: 'Email Domains', route: '/tools/domains', icon: 'fad fa-at fa-fw me-2' },
  ]

  user$: Observable<User>

  build = environment.build

  @LocalStorage()
  sidebarMinimized = false;
  dataSource: Observable<unknown>;
  typeaheadLoading: boolean;

  constructor(
    private authService: AuthService,
    private http: ApiHttpClient,
  ) {
    this.dataSource = new Observable((observer: Subscriber<string>) => {
      observer.next(this.asyncSelected);
    })
      .pipe(
        mergeMap((token: string) => this.getStatesAsObservable(token))
      );
  }

  asyncSelected?: string;

  ngOnInit() {
    this.user$ = this.authService.loadUser()
  }

  toggleMinimize() {
    this.sidebarMinimized = !this.sidebarMinimized;
  }

  getStatesAsObservable(term: string) {
    return this.http.get<{ items: any[] }>("/search", {
      params: {
        term
      }
    }).pipe(
      map(x => x.items)
    )
  }

  changeTypeaheadLoading(e: boolean): void {
    this.typeaheadLoading = e;
  }

  onSelect(event) {
    console.log(event)
  }

}
