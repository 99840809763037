import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@shared/shared.module';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { BoxLayoutComponent } from './box/box.component';
import { MainLayoutComponent } from './main/main-layout.component';

@NgModule({
  imports: [
    SharedModule,
    RouterModule.forChild([]),
    PerfectScrollbarModule,
    BsDropdownModule,
    TabsModule,
    TypeaheadModule
  ],
  declarations: [
    MainLayoutComponent,
    BoxLayoutComponent
  ]
})
export class LayoutsModule { }
