import { Injectable } from "@angular/core";
import { LocalStorage } from '@efaps/ngx-store';
import { of } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { ApiHttpClient } from "./api-auth-client";

export interface User {
  id: string
  email: string
  subscription: string
  apiKey: string
  features: string[]
  trialEnds: Date
}

@Injectable()
export class AuthService {

  @LocalStorage()
  userId?: string | null;

  @LocalStorage()
  token?: string | null;

  user: User | null = null;

  constructor(
    private http: ApiHttpClient,
  ) {
  }

  login = (email: any, password: any) => this.http
    .post<{ userId?: string, token?: string }>("/auth", {
      email,
      password,
    })
    .pipe(
      tap((resp) => {
        this.userId = resp.userId || null
        this.token = resp.token || null
        this.loadUser().subscribe()
      })
    )

  logout() {
    this.userId = null
    this.user = null
    this.token = null
  }

  loadUser() {
    return this.http
      .get<User>("/me")
      .pipe(
        catchError((resp) => {
          if (resp.status === 401 || resp.status === 404) {
            window.location.href = "/auth/logout"
          }
          return of(null)
        }),
        tap((user) => {
          this.user = user
        }),
      )
  }
}
