<div id="wrapper">

  <ul class="navbar-nav bg-gradient-dark sidebar sidebar-dark accordion" [ngClass]="{toggled:sidebarMinimized}">

    <a class="sidebar-brand d-flex align-items-center justify-content-center" routerLink="/">
      <div class="sidebar-brand-icon">
        <i class="fas fa-bolt"></i>
      </div>
      <div class="sidebar-brand-text mx-3">DataFlash</div>
    </a>

    <hr class="sidebar-divider mb-1">

    <ng-container *ngFor="let m of menu">
      <ng-container [ngSwitch]="m.type">
        <ng-container *ngSwitchCase="'item'">
          <li class="nav-item" routerLinkActive="active">
            <a class="nav-link" [routerLink]="m.route">
              <i [class]="m.icon"></i>
              <span>{{m.label}}</span>
            </a>
          </li>
        </ng-container>
        <ng-container *ngSwitchCase="'title'">
          <hr class="sidebar-divider mb-1">
        </ng-container>
      </ng-container>
    </ng-container>

    <hr class="sidebar-divider mb-1">

    <li class="nav-item">
      <a class="nav-link" routerLink="/auth/logout">
        <i class="fas fa-fw me-2 fa-sign-out"></i>
        <span>Logout</span>
      </a>
    </li>

    <div class="mt-auto d-flex flex-column align-items-center">
      <div class="text-center d-none d-md-inline">
        <button class="btn bg-dark rounded-circle m-4" (click)="toggleMinimize()">
          <span *ngIf="sidebarMinimized" class="fas fa-chevron-right"></span>
          <span *ngIf="!sidebarMinimized" class="fas fa-chevron-left"></span>
        </button>
      </div>
      <hr class="sidebar-divider w-100 m-0">

      <app-serverinfo *ngIf="!sidebarMinimized"></app-serverinfo>

      <div class="sidebar-heading text-center p-2">
        {{build.date|date:"yyyy-MM-dd HH:mm:ss"}} {{build.hash|slice:0:8}}
      </div>
    </div>
  </ul>

  <div id="content">

    <!-- <nav class="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow"> -->

    <!-- Topbar Search -->
    <!-- <form class="d-none d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100 navbar-search">
        <div class="input-group">
          <input class="form-control bg-light border-0 small" [(ngModel)]="asyncSelected" [typeahead]="dataSource"
            [typeaheadAsync]="true" (typeaheadLoading)="changeTypeaheadLoading($event)" typeaheadOptionField="label"
            placeholder="Search for..." typeaheadGroupField="type" class="form-control"
            [typeaheadItemTemplate]="customItemTemplate" [typeaheadOptionsInScrollableView]="15"
            (typeaheadOnSelect)="onSelect($event)">
          <div class="input-group-append">
            <button class="btn btn-dark" type="button">
              <i class="fas fa-search fa-sm"></i>
            </button>
          </div>
        </div>
      </form> -->

    <!-- Topbar Navbar -->
    <!-- <ul class="navbar-nav ml-auto"> -->

    <!-- Nav Item - User Information -->
    <!-- <li class="nav-item dropdown no-arrow" dropdown>
          <button id="button-basic" dropdownToggle type="button"
            class="btn btn-sm border-0 btn-outline-secondary border-none dropdown-toggle">
            {{(user$|async)?.email || '---'}} <span class="caret"></span>
          </button>
          <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu">
            <li role="menuitem"><a class="dropdown-item" [routerLink]="[ '/auth/logout']">Logout</a></li>
          </ul>
        </li>

      </ul>

    </nav> -->

    <div class="container-fluid pt-4">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>

<ng-template #customItemTemplate let-model="item" let-index="index">
  {{model.label}}
</ng-template>
