import { HttpClient, HttpHandler } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { Observable, of } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { environment } from "../../../environments/environment";

@Injectable()
export class ApiHttpClient extends HttpClient {

  public baseUrl: string;

  public constructor(
    handler: HttpHandler,
    private toastrService: ToastrService
  ) {
    super(handler);
    this.baseUrl = environment.apiUrl;
  }

  get<T>(url: string, options?: Object): Observable<T> {
    return super.get<T>(`${this.baseUrl}${url}`, options)
      .pipe(
        tap((resp: any) => {
          // console.log(resp?.status)
          // if (resp?.status && resp?.status !== 200) {
          //   this.toastrService.error(resp.message)
          // }
        }),
        catchError((resp) => {
          console.log(resp)
          this.toastrService.error(resp.error.message)
          return of({})
        }),
      );
  }

  post<T>(url: string, body: any, options?: Object): Observable<T> {
    return super.post<T>(`${this.baseUrl}${url}`, body, options)
      .pipe(
        tap((resp: any) => {
          if (resp?.status && resp?.status !== 201) {
            this.toastrService.error(resp.message)
          }
        }),
        catchError((resp) => {
          console.log(resp)
          this.toastrService.error(resp.error.message)
          return of({})
        }),
      );
  }

  put<T>(url: string, body: any, options?: Object): Observable<T> {
    return super.put<T>(`${this.baseUrl}${url}`, body, options)
      .pipe(
        tap((resp: any) => {
          if (resp?.status && resp?.status !== 200) {
            // this.toastrService.error(resp.message)
          }
        }),
        catchError((resp) => {
          console.log(resp)
          this.toastrService.error(resp.error?.message)
          return of({})
        }),
      );
  }

  delete<T>(url: string, options?: Object): Observable<T> {
    return super.delete<T>(`${this.baseUrl}${url}`, options)
      .pipe(
        tap((resp: any) => {
          if (resp?.status && resp?.status !== 200) {
            this.toastrService.error(resp.message)
          }
        }),
        catchError((resp) => {
          console.log(resp)
          this.toastrService.error(resp.error.message)
          return of({})
        }),
      );
  }
}
